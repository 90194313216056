import axios from 'axios';
import { Message } from 'element-ui';
import { logout } from './auth';

const { error: showError } = Message;
const instance = axios.create();
const codeMessage = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

// Add a request interceptor
axios.interceptors.request.use((config) => config, (error) => Promise.reject(error));

// Add a response interceptor
instance.interceptors.response.use((res) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  const { data, data: { errcode, msg } } = res;
  if (errcode) {
    // 登录 token 失效
    if (errcode === 401) {
      showError('请先登录');
      logout();
    } else {
      showError(msg);
    }
    return Promise.reject();
  }
  return { ...data, originalResponse: res };
}, (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  const { response: res } = error;
  showError(
    (res && codeMessage[res.status] && `${res.status}: ${codeMessage[res.status]}`)
    || error.message || '服务器出错了~',
  );

  return Promise.reject(error);
});

export default instance;
