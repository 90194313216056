<template>
  <div class="tuner">
    <!-- <div class="title">
    <img src="../assets/back_icon.png" alt="">
    <div class="title-name">调音器</div>
  </div> -->
    <div class="tuner-head">
      <!-- 切换 -->
      <div class="switch-item">
        <div class="item" :class="{ active: ifChoose == 2 }" @click="switchItem">尤克里里</div>
        <div class="item" :class="{ active: ifChoose == 1 }" @click="switchItem2">吉他</div>
      </div>
      <!-- 频率高低 -->
      <div class="tone">
        <div class="tone-box">
          <div class="tone-item">
            <div class="tone-box-name lowActive" v-if="frequencyStatus == 0">偏低</div>
            <div class="tone-box-name" v-else>偏低</div>
            <div class="tone-C CActive" v-if="frequencyStatus == 1">
              {{ xianKey }}
            </div>
            <div class="tone-C" v-else>{{ xianKey }}</div>
            <div class="tone-box-name lowActive" v-if="frequencyStatus == 2">偏高</div>
            <div class="tone-box-name" v-else>偏高</div>
          </div>
          <div class="tone-status">
            <div class="inappropriate" v-if="frequencyStatus == 0"></div>
            <div class="unstatus" v-else></div>
            <div class="appropriate" v-if="frequencyStatus == 1"></div>
            <div class="unstatus" v-else></div>
            <div class="inappropriate" v-if="frequencyStatus == 2"></div>
            <div class="unstatus" v-else></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择弦 -->
    <div class="Tuning">
      <!-- 尤克里里 -->
      <div class="Tuning-box" v-if="ifChoose == 2">
        <div class="Tuning-left">
          <div class="Tuning-item" :class="{ keyActive: yukriCode == 'C' }" data-type="C" @click="yukriKey($event)">C</div>
          <div class="Tuning-item" :class="{ keyActive: yukriCode == 'G' }" data-type="G" @click="yukriKey($event)">G</div>
        </div>
        <div class="Tuning-center">
          <div class="Yukri-content">
            <!-- <div class="Yukri-bg"></div> -->
            <img class="Yukri-bg" src="../assets/ukulelebg@2x.png" alt="" />
            <div class="Yukri-phtot">
              <div class="Yukri-list">
                <img class="Yukri-G" v-if="yukriCode == 'G'" src="../assets/U_lineA_sel@2x.png" alt="" />
                <img class="Yukri-G" v-else src="../assets/U_lineG_def@2x.png" alt="" />
                <img class="Yukri-C" v-if="yukriCode == 'C'" src="../assets/U_lineC_sel@2x.png" alt="" />
                <img class="Yukri-C" v-else src="../assets/U_lineC_def@2x.png" alt="" />
                <img class="Yukri-E" v-if="yukriCode == 'E'" src="../assets/U_lineE_sel@2x.png" alt="" />
                <img class="Yukri-E" v-else src="../assets/U_lineE_def@2x.png" alt="" />
                <img class="Yukri-A" v-if="yukriCode == 'A'" src="../assets/U_lineA_sel@2x.png" alt="" />
                <img class="Yukri-A" v-else src="../assets/U_lineA_def@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="number-list">
            <div class="Yukri-number" :class="{ Yukrinumberactive: yukriCode == 'G' }">4</div>
            <div class="Yukri-number" :class="{ Yukrinumberactive: yukriCode == 'C' }">3</div>
            <div class="Yukri-number" :class="{ Yukrinumberactive: yukriCode == 'E' }">2</div>
            <div class="Yukri-number" :class="{ Yukrinumberactive: yukriCode == 'A' }">1</div>
          </div>
        </div>
        <div class="Tuning-right">
          <div class="Tuning-item" :class="{ keyActive: yukriCode == 'E' }" data-type="E" @click="yukriKey($event)">E</div>
          <div class="Tuning-item" :class="{ keyActive: yukriCode == 'A' }" data-type="A" @click="yukriKey($event)">A</div>
        </div>
      </div>
      <!-- 吉他 -->
      <div class="Tuning-box" v-if="ifChoose == 1">
        <div class="guitar-left">
          <div class="guitar-item" :class="{ keyActive2: guitarCode == 'D' }" data-type="D" @click="guitarKey($event)">D</div>
          <div class="guitar-item" :class="{ keyActive2: guitarCode == 'A' }" data-type="A" @click="guitarKey($event)">A</div>
          <div class="guitar-item" :class="{ keyActive2: guitarCode == 'E' }" data-type="E" @click="guitarKey($event)">E</div>
        </div>
        <div class="Tuning-center">
          <div class="Yukri-content">
            <!-- <div class="Yukri-bg"></div> -->
            <img class="Yukri-bg" src="../assets/gitarbg@2x.png" alt="" />
            <div class="guitar-phtot">
              <div class="Yukri-list">
                <img class="guitar-E" v-if="guitarCode == 'E'" src="../assets/G_lineE_sel@2x.png" alt="" />
                <img class="guitar-E" v-else src="../assets/G_lineE_def@2x.png" alt="" />
                <img class="guitar-A" v-if="guitarCode == 'A'" src="../assets/G_lineA_sel@2x.png" alt="" />
                <img class="guitar-A" v-else src="../assets/G_lineA_def@2x.png" alt="" />
                <img class="guitar-D" v-if="guitarCode == 'D'" src="../assets/G_lineD_sel@2x.png" alt="" />
                <img class="guitar-D" v-else src="../assets/G_lineD_def@2x.png" alt="" />
                <img class="guitar-G" v-if="guitarCode == 'G'" src="../assets/G_lineG_sel@2x.png" alt="" />
                <img class="guitar-G" v-else src="../assets/G_lineG_def@2x.png" alt="" />
                <img class="guitar-B" v-if="guitarCode == 'B'" src="../assets/G_lineB_sel@2x.png" alt="" />
                <img class="guitar-B" v-else src="../assets/G_lineB_def@2x.png" alt="" />
                <img class="guitar-E2" v-if="guitarCode == 'E2'" src="../assets/G_lineE_sel@2x.png" alt="" />
                <img class="guitar-E2" v-else src="../assets/G_lineE_def@2x.png" alt="" />
              </div>
            </div>
          </div>
          <div class="number-list">
            <div class="guitar-number" :class="{ guitarnumberactive: guitarCode == 'E' }">6</div>
            <div class="guitar-number" :class="{ guitarnumberactive: guitarCode == 'A' }">5</div>
            <div class="guitar-number" :class="{ guitarnumberactive: guitarCode == 'D' }">4</div>
            <div class="guitar-number" :class="{ guitarnumberactive: guitarCode == 'G' }">3</div>
            <div class="guitar-number" :class="{ guitarnumberactive: guitarCode == 'B' }">2</div>
            <div class="guitar-number" :class="{ guitarnumberactive: guitarCode == 'E2' }">1</div>
          </div>
        </div>
        <div class="guitar-right">
          <div class="guitar-item" :class="{ keyActive2: guitarCode == 'G' }" data-type="G" @click="guitarKey($event)">G</div>
          <div class="guitar-item" :class="{ keyActive2: guitarCode == 'B' }" data-type="B" @click="guitarKey($event)">B</div>
          <div class="guitar-item" :class="{ keyActive2: guitarCode == 'E2' }" data-type="E2" @click="guitarKey($event)">E</div>
        </div>
      </div>
    </div>
    <div class="bg_black" v-if="tip">
      <div class="explain-box">
        <div class="explain-content">
          <div class="explain-tip">温馨提示</div>
          <div class="content">如您使用苹果手机</div>
          <div class="content">请确认您的系统在14.3及以上</div>
          <div class="content">如低于该系统型号则无法使用本功能</div>
          <div class="content">我们将尽快完成调音器功能的优化</div>
          <div class="content">希望您能理解</div>
          <div class="explain-btn" @click="closeTip">确认</div>
        </div>
        <div class="close-icon_box">
          <img class="close-icon" @click="closeTip" src="../assets/off@2x.png" />
        </div>
      </div>
    </div>
    <!-- <div class="frequency">
      <span>当前值:</span>
      <div class="current-frequency">{{ frequency }}</div>
      <span>Hz</span>
      <div class="current-frequency">{{ time }}</div>
      <div class="current-frequency">{{ ifChoose }}</div>
      <div class="current-frequency">{{ yukriCode }}</div>
      <div class="current-frequency">{{ guitarCode }}</div>
      <div class="current-frequency">{{ standardFrequencys }}</div>
      <div class="current-frequency">{{ maxfrequency }}</div>
    </div>
    <button @click="startBtn()">启动</button>
    <button @click="stopBtn()">停止</button> -->
  </div>
  <!-- <el-card>
    <div slot="header" class="clearfix">
      <span>首页</span>
    </div>
    <el-table
      v-loading.fullscreen="loading"
      :data="list"
      >
      <el-table-column
        prop="name"
        label="名称"
        />
      </el-table>
  </el-card> -->
</template>

<script>
const Aubio = require('../utils/aubio');

export default {
  name: 'Home',
  data() {
    return {
      loading: true,
      firstRight: true,
      ifChoose: 2, // false尤克里里，true吉他
      list: [],
      frequency: '-',
      frequencyList: [],
      yukriCode: '',
      guitarCode: '',
      xianKey: 'C',
      standardFrequencys: [],
      maxfrequency: '',
      time: '',
      Yukri: [
        {
          name: 'G',
          def: require('../assets/U_lineG_def@2x.png'),
          sel: require('../assets/U_lineA_sel@2x.png'),
        },
        {
          name: 'C',
          def: require('../assets/U_lineC_def@2x.png'),
          sel: require('../assets/U_lineC_sel@2x.png'),
        },
        {
          name: 'E',
          def: require('../assets/U_lineE_def@2x.png'),
          sel: require('../assets/U_lineE_sel@2x.png'),
        },
        {
          name: 'A',
          def: require('../assets/U_lineA_def@2x.png'),
          sel: require('../assets/U_lineA_sel@2x.png'),
        },
      ],
      frequencyListDatas: {
        2: {
          A: 440.0,
          E: 329.6,
          C: 261.6,
          G: 392.0,
        },
        1: {
          E2: 329.6,
          B: 246.9,
          G: 195.9,
          D: 146.8,
          A: 110.0,
          E: 82.4,
        },
      },
      frequencyStatus: 4, // 频率状态：0低了，1合适，2高了
      tip: false,
    };
  },
  created() {
    // this.fetchList();
    const tip = localStorage.getItem('tip');
    this.tip = !tip;
    localStorage.setItem('tip', 1);
  },
  methods: {
    // async fetchList() {
    //   try {
    //     const { data } = await this.$http.get('/wxarticle/chapters/json');
    //     this.list = data;
    //   } finally {
    //     this.loading = false;
    //   }
    // },
    // 切换尤克里里、吉他
    closeTip() {
      this.tip = false;
    },
    switchItem() {
      this.ifChoose = 2;
      this.yukriCode = '';
      this.guitarCode = '';
    },
    switchItem2() {
      this.ifChoose = 1;
      this.yukriCode = '';
      this.guitarCode = '';
    },
    // 尤克里里点击选中弦
    yukriKey(e) {
      this.yukriCode = e.target.dataset.type;
      this.xianKey = e.target.dataset.type;
      if (this.firstRight) this.startBtn();
      // this.startBtn();
    },
    // 吉他点击选中弦
    guitarKey(e) {
      this.guitarCode = e.target.dataset.type;
      this.xianKey = e.target.dataset.type;
      if (this.firstRight) this.startBtn();
      // this.startBtn();
    },
    initGetUserMedia() {
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      if (navigator.mediaDevices.getUserMedia === undefined) {
        navigator.mediaDevices.getUserMedia = function(constraints) {
          const getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
          if (!getUserMedia) {
            return Promise.reject(new Error('getUserMedia is not implemented in this browser'));
          }
          return new Promise((resolve, reject) => {
            getUserMedia(constraints, resolve, reject);
          });
        };
      }
    },
    startBtn() {
      this.firstRight = false;
      const that = this;
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      this.initGetUserMedia();
      const bufferSize = 4096;
      const scriptProcessor = audioContext.createScriptProcessor(bufferSize, 1, 1);
      console.log('navigator', navigator);
      console.log('navigator.mediaDevices', navigator.mediaDevices);
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        audioContext.createMediaStreamSource(stream).connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);
        Aubio().then((aubio) => {
          const fft = new aubio.FFT(2048);
          const pitchDetector = new aubio.Pitch('default', scriptProcessor.bufferSize, 1, audioContext.sampleRate);
          scriptProcessor.addEventListener('audioprocess', function(event) {
            const spectrum = fft.forward(event.inputBuffer.getChannelData(0));
            this.frequencyData = spectrum.norm;
            const maxvalue = Math.max(...this.frequencyData);
            that.maxfrequency = maxvalue;
            const newfrequency = pitchDetector.do(event.inputBuffer.getChannelData(0));
            that.frequency = newfrequency;
            // 获取乐器
            // 获取对应的音
            // 获取标准音高
            let standardFrequencys = 0;
            if (that.ifChoose === 1) {
              standardFrequencys = that.frequencyListDatas[that.ifChoose][that.guitarCode];
            } else {
              standardFrequencys = that.frequencyListDatas[that.ifChoose][that.yukriCode];
            }
            that.standardFrequencys = standardFrequencys;

            // if (newfrequency >= (standardFrequencys - 50) || newfrequency <= (standardFrequencys + 50)) {
            if (maxvalue > 10) {
              // 开始判断
              if (newfrequency.toFixed(1) > standardFrequencys + 1) {
                that.frequencyStatus = 2;
              } else if (newfrequency.toFixed(1) < standardFrequencys - 1) {
                that.frequencyStatus = 0;
              } else {
                that.frequencyStatus = 1;
              }
            }
            if (maxvalue < 10) {
              that.frequencyStatus = 4;
            }
            // that.time = Date.now();
            // if (that.frequencyList.length < 10) {
            //   that.frequencyList.append(that.frequency);
            // } else {
            //   that.frequencyList = [];
            // }
            // if (that.frequency < 110) {
            //   that.frequencyStatus = 0;
            // } else {
            //   that.frequencyStatus = 2;
            // }
          });
        });
      });
    },
    stopBtn() {},
  },
};
</script>
<style>
.tuner {
  width: 100%;
}
.title {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.title img {
  width: 24px;
  height: 24px;
}
.title-name {
  font-size: 17px;
  font-weight: 800;
  color: #2f2f30;
  margin-left: 9px;
}
.tuner-head {
  background: #f8f8f8;
  padding: 20px 20px 54px 20px;
}
.switch-item {
  width: 125px;
  background: #fff;
  border-radius: 20px;
  border: 1px solid #e2e2e2;
  display: flex;
  align-items: center;
}
.item {
  width: 64px;
  height: 26px;
  line-height: 26px;
  font-size: 10px;
  background: #ffffff;
  border: none;
  border-radius: 14px;
  font-size: 10px;
  text-align: center;
}
.active {
  background: #ffecf4;
  border: 1px solid #fb4995;
  border-radius: 14px;
  font-size: 10px;
  text-align: center;
  width: 62px;
  height: 26px;
  line-height: 26px;
}
.tone {
  padding: 0 13px;
  margin-top: 55px;
}
.tone-box {
  background: #ffffff;
  border-radius: 11px;
  height: 102px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.tone-item {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.tone-box-name {
  font-size: 20px;
  font-weight: 700;
}
.lowActive {
  font-size: 20px;
  font-weight: 700;
  color: #fc8484;
}
.CActive {
  font-size: 60px;
  font-weight: 700;
  color: #81e3cc;
}
.tone-C {
  font-size: 60px;
  font-weight: 700;
}
.tone-status {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  bottom: -7px;
}
.unstatus {
  width: 15px;
  height: 15px;
  background: #ffffff;
  transform: rotate(45deg);
}
.inappropriate {
  width: 15px;
  height: 15px;
  background: #fc8484;
  transform: rotate(45deg);
}
.appropriate {
  width: 15px;
  height: 15px;
  background: #81e3cc;
  transform: rotate(45deg);
}
.Tuning {
  /* padding: 57px 42px; */
  padding: 45px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Tuning-box {
  width: 100%;
  display: flex;
  justify-content: space-around;
}
.Tuning-left {
  height: 100%;
  margin-top: 60px;
}
.Tuning-right {
  height: 100%;
  margin-top: 60px;
}
.Tuning-item {
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 50px;
  border: 1px solid #d2d2d2;
  border-radius: 50%;
  font-size: 22px;
  font-weight: 700;
  color: #181818;
  margin-bottom: 60px;
}
.keyActive {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: linear-gradient(222deg, #ffe0b7 0%, #fb4995 89%);
  border-radius: 50%;
  border: none;
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 60px;
}
.Yukri-content {
  width: 160px;
  height: 324px;
  position: relative;
}
.number-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7px;
}
.Yukri-number {
  font-size: 12px;
  font-weight: 700;
  color: #a5a5a5;
  margin: 0 10px;
}
.Yukrinumberactive {
  font-size: 17px;
  font-weight: 700;
  color: #181818;
}
.Yukri-bg {
  width: 100%;
  height: 100%;
  /* background: #f3f3f3;
  border-radius: 50px 50px 35px 35px; */
}
.Yukri-phtot {
  display: flex;
  position: absolute;
  bottom: -4px;
  left: 10px;
}
.Yukri-list {
  width: 100%;
  height: 100%;
  position: relative;
}
.Yukri-G {
  width: 27px;
  height: 141px;
  position: absolute;
  bottom: 4px;
  left: 17px;
}
.Yukri-C {
  width: 42px;
  height: 252px;
  position: absolute;
  bottom: 4px;
  left: 15px;
}
.Yukri-E {
  width: 42px;
  height: 252px;
  position: absolute;
  bottom: 4px;
  left: 82px;
}
.Yukri-A {
  width: 27px;
  height: 141px;
  position: absolute;
  bottom: 4px;
  left: 96px;
}
/* 吉他样式 */
.guitar-item {
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 50px;
  border: 1px solid #d2d2d2;
  border-radius: 50%;
  font-size: 22px;
  font-weight: 700;
  color: #181818;
  margin-bottom: 25px;
}
.keyActive2 {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  background: linear-gradient(222deg, #ffe0b7 0%, #fb4995 89%);
  border-radius: 50%;
  border: none;
  font-size: 22px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 25px;
}
.guitar-left {
  height: 100%;
  margin-top: 45px;
}
.guitar-right {
  height: 100%;
  margin-top: 45px;
}
.guitar-phtot {
  display: flex;
  position: absolute;
  bottom: -4px;
  left: 10px;
}
.guitar-E {
  width: 27px;
  height: 122px;
  position: absolute;
  bottom: 4px;
  left: 15px;
}
.guitar-A {
  width: 32px;
  height: 196px;
  position: absolute;
  bottom: 4px;
  left: 15px;
}
.guitar-D {
  width: 48px;
  height: 266px;
  position: absolute;
  bottom: 4px;
  left: 15px;
}
.guitar-G {
  width: 48px;
  height: 266px;
  position: absolute;
  bottom: 4px;
  left: 78px;
}
.guitar-B {
  width: 32px;
  height: 196px;
  position: absolute;
  bottom: 4px;
  left: 95px;
}
.guitar-E2 {
  width: 27px;
  height: 122px;
  position: absolute;
  bottom: 4px;
  left: 99px;
}
.guitar-number {
  font-size: 12px;
  font-weight: 700;
  color: #a5a5a5;
  margin: 0 5px;
}
.guitarnumberactive {
  font-size: 17px;
  font-weight: 700;
  color: #181818;
}
.bg_black {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}
.explain-box {
  padding: 0 20px;
  width: 100%;
}
.explain-content {
  background: #ffffff;
  border-radius: 10px;
  padding: 20px;
}
.explain-tip {
  font-size: 17px;
  font-weight: 800;
  text-align: center;
  color: #181818;
  margin-bottom: 20px;
}
.explain-btn {
  height: 44px;
  background: linear-gradient(226deg, #ffe0b7 3%, #fb4995 81%);
  border-radius: 22px;
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  color: #ffffff !important;
  line-height: 46px;
  margin-top: 20px;
}
.close-icon_box {
  display: flex;
  justify-content: center;
  align-items: center;
}
.close-icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-top: 20px;
}
.content {
  font-size: 15px;
  font-weight: Normal;
  text-align: center;
  color: #181818;
}
</style>
